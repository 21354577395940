
.navBar {
    background-color: #ffbb00db;
    backdrop-filter: blur(10px);
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
  }
  
  .navContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    /* height: 15vh; */
    max-width: 1500px;
    position: relative;
  }
  
  .navLogo {
    color: #fff;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
  
    display: flex;
    align-items: center;
    justify-items: center;
  }
  
  .navMenu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
  }
  
  .navLinks {
    color: #000;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  
  .navItem, .navItemLast {
    line-height: 40px;
    margin-right: 1rem;
  }
  
  .navItem:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .navItem:hover:after {
    width: 100%;
    background: #A60000;
  }

  .navItemLast{
    background-color: #A60000;
  }

  .navItemLast .navLinks{
    color: #fff;
  }

  .navItemLast .navLinks:hover{
    background-color: #fff;
    color: #A60000;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .navItem .active {
    color: #A60000;
  }
  
  .navIcon {
    display: none;
    color: #fff;
  }
  
  .navIcon .icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
  }


  @media screen and (max-width: 960px) {
    .navMenu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1px solid #fff;
      position: absolute;
      /* top: 80px; */
      top: 14vh;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .navMenu.active {
      background: #FFBA00;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .navItem .active {
      color: #A60000;
      border: none;
    }
    .navLinks {
      padding: 1rem;
      width: 100%;
      display: table;
    }
  
    .navIcon {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-100%, -50%);
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      color: #A60000;
    }
  }