.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% 5%;
    padding: 5% 0%;
    border-radius: 40px 0 40px 0;
    background: linear-gradient(15deg, rgba(255, 186, 0, 0.18) 1.7%, rgba(255, 186, 0, 0.00) 102.34%);
}

.applicationForm {
    padding: 5%;
    width: 400px;
    /* max-width: 400px; */
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.5s ease-in-out;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.applicationForm h2 {
    text-align: center;
    color: #333;
}

.applicationForm p {
    text-align: center;
    margin: 5px 0 10px 0;
    font-size: 12px;
}

.applicationForm span{
   color: #A60000;
}

.applicationForm input,
.applicationForm select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.applicationForm input:focus,
.applicationForm select:focus{
    outline-color: #FFBA00;
}

.applicationForm button {
    width: 100%;
    padding: 10px;
    background-color: #A60000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.applicationForm button:hover {
    background-color: #FFBA00;
}

.error {
    width: 100%;
    color: red;
    font-size: 0.8em;
    margin-bottom: 10px;
}

