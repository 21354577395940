.interestContainer{
    background-color: #FFBA00;
    border-radius: 40px 0 40px 0;
    margin: 2% 5%;
    padding: 5% 0;
}

.interestRow{
    display: flex;
    gap: 50px;
    padding: 2.5rem;
}

.interestTitle{
    font-weight: 600;
    font-size: 2.5rem;
}

.interestColumn{
    padding: 0 0 0 100px;
    text-align: left;
}

.interestMainText{
    margin-bottom: 20px;
}

.interestContainer .link{
    background-color: #A60000;
    text-align: left;
    text-decoration: none;
    color: #fff;
    padding: 5px 10px;
    border: 1px solid #A60000;
}

.interestContainer .link:hover{
    border: 1px solid #fff;
    transition: boder 1s ease-in-out;
}

.cards{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 20px rgba(0,0,0,0.2);
}

.card:hover{
    transform: translateY(-5px);
    transition: transform 0.3s ease;
}

.cards .card{
    height: 200px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
}

.cards .card .bigText{
    font-size: 2rem;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .interestColumn{
        padding: 0;
    }

    .interestRow{
        flex-direction: column;
        padding: 2.5rem;
    }
    
    .interestTitle{
        font-size: 2rem;
    }

    .cards{
        flex-direction: column;
    }
}