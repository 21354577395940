.servicesContainer{
    text-align: center;
    padding: 10% 5%;
    background: linear-gradient(15deg, rgba(255, 186, 0, 0.18) 1.7%, rgba(255, 186, 0, 0.00) 102.34%);
}

.servicesHeader{
    margin-bottom: 4rem;
}

.servicesTitle{
    font-size: 2rem;
    font-weight: 600;
}

.servicesText{
    padding: 0 20%;
}

.servicesCards{
    display: flex;
    align-items: flex-start;
    gap: 30px;
}

.servicesCard{
    flex-basis: 100%;
}

.cardTitle{
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .servicesTitle{
        font-size: 1.5rem;
    }

    .servicesCards{
        flex-direction: column;
    }

    .servicesText{
        padding: 0;
    }
}
