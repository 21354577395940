  footer {
    bottom: 0;
    width: 100%;
    /* min-height: 45vh; */
    color: #fff;
    line-height: 2rem;
    background: #A60000;
    padding: 5% 5%;
  }

  .whatsappLink{
    color: #52CC5D;
    position: fixed;
    z-index: 999;
    bottom: 5%;
    right: 4%;
  }

  .whatsappIcon{
    font-size: 3rem;
  }

  .mainContentstyle{
    display: flex;
    gap: 30px;
    border-bottom: 1px solid #fff;
  }

  .mainContentstyle .box{
    flex-basis: 100%;
  }

  .mainContentstyle .locationIcon{
    font-size: 1.3rem;
  }

  .mainContentstyle .titleText{
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .mainContentstyle .content .text{
    color: #fff;
    text-decoration: none;
  }

  .mainContentstyle .social{
    margin: 25px;
  }

  .mainContentstyle .socialLink{
    margin-right: 15px;
    color: #FFBA00;
  }

  .mainContentstyle .footerLinks{
    list-style: none;
  }

  .mainContentstyle .footerLinks .footerLink{
    color: #fff;
    text-decoration: none;
    color: #FFBA00;
    border-bottom: 1px solid #fff;
    margin-bottom: 20px;
  }

  .mainContentstyle .footerLinks .footerLink:hover{
    color: #fff;
  }

  /* .mainContentstyle .emailField{
    width: 85%;
  } */

  /* .mainContentstyle .subscribeBtn{
    padding: 5px 15px;
    background-color: #FFBA00;
    border-radius: 5px;
    outline: none;
    border: none;
  } */

  /* .mainContentstyle .subscribeBtn:hover{
    opacity: 0.8;
  } */
/* 
  .mainContentstyle .emailField{
    outline: none;
    border: none;
    padding: 5px 15px;
    margin-bottom: 10px;
  } */

  .mainContentstyle .phone{
    margin: 15px 0;
  }

  .mainContentstyle .phone, .mainContentstyle .emails, .mainContentstyle .place{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .copyright{
    text-align: center;
    margin-top: 15px;
  }


  @media screen and (max-width: 768px) {
    footer{
      padding: 10% 5%;
    }

    .mainContentstyle{
      flex-wrap: wrap;
    }

    .mainContentstyle .titleText{
      display: inline-block;
      border-bottom: 0.5px solid #ffbb00b0;
    }

    .subscribeForm{
      margin: 25px 0;
    }

    .mainContentstyle .emailField{
      width: 70%;
    }
  }