.eventsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    margin: 2% 5%;
    /* padding: 5% 0; */
}

.eventCard {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.eventCard:hover {
    transform: scale(1.05);
}

.eventImage {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.eventImage:hover {
    transform: scale(1.1);
}

.eventTitle {
    padding: 10px;
    text-align: center;
}