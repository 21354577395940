.testimonialsContainer{
    text-align: center;
    padding: 10% 5%;
    background: linear-gradient(115deg, #FFF -9.3%, rgba(255, 186, 0, 0.18) 96.95%);
}

.testimonialsHeader{
    margin-bottom: 4rem;
}

.testimonialsTitle{
    font-size: 2rem;
    font-weight: 600;
}

.testimonialsText{
    padding: 0 20%;
}

.testimonialsYoutube{
    border-radius: 30px;
    background-color: #000;
    margin-bottom: 100px;
}