.teamContainer{
    background: linear-gradient(15deg, rgba(255, 186, 0, 0.18) 1.7%, rgba(255, 186, 0, 0.00) 102.34%);
    padding: 2% 5%;
}

.teamHeader{
    padding: 2.5rem;
    text-align: center;
}

.headerContainer{
    background-color: #fff;
    border-radius: 40px 0 40px 0;
}

.teamTitle{
    font-weight: 600;
    font-size: 2.5rem;
}

.teamMainText{
    margin-bottom: 20px;
}

.teamCardContainer{
    padding-bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}