.teamMemberCard {
    width: 300px;
    /* background: linear-gradient(15deg, rgba(255, 186, 0, 0.18) 1.7%, rgba(255, 186, 0, 0.00) 102.34%);  */
    /* border: 1px solid #FFBA00; */
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.teamMemberCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.cardContent img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid #FFBA00;
}

.cardContent h3 {
    margin: 0;
    color: #A60000;
}

.cardContent p {
    margin: 5px 0 0;
    color: #666;
}