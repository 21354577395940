.heroSection{
    height: 95vh;
    background-image: url('../../../assets/coding-guy.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.heroContent{
    height: 100%;
    padding: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    background-color: #000000b7;
    color: #fff;
}

.heroTinyText{
    padding: 5px 8px;
    border: 1px solid #fff;
    border-radius: 15px;
}

.orangeText{
    -webkit-text-stroke: 2px #FFBA00;
    color: transparent;
    font-weight: 800;
    font-size: 8rem;
}

.heroH1{
    font-size: 5rem;
    font-weight: 800;
    line-height: 5.5rem;
}

.heroMainText{
    font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
    .heroTinyText{
        text-align: center;
    }

    .heroContent{
        align-items: flex-start;
    }

    .orangeText{
        font-size: 3rem;
    }
    
    .heroH1{
        font-size: 3rem;
        line-height: 3.5rem;
    }
    
    .heroMainText{
        font-size: 1rem;
    }
}

@media screen and (max-width: 400px) {
    .heroSection{
        height: 100vh;
    }

    .heroH1{
        font-size: 2.5rem;
        line-height: 2.5rem;
    }
}