.accordionSection{
    padding: 0 5%;
}

.faqHeader{
    margin: 4rem 0;
    text-align: center;
}

.faqTitle{
    font-size: 2rem;
    font-weight: 600;
}

.faqText{
    padding: 0 20%;
}

.FaqAccordion {
    width: 100%;
    background: linear-gradient(15deg, rgba(255, 186, 0, 0.18) 1.7%, rgba(255, 186, 0, 0.00) 102.34%);
}

.FaqQuestionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    color: #A60000;
    font-size: 1.5rem;
    padding-right: 1rem;

}

.questionSection{
    margin-bottom: 1px;
}

.questionStyle {
    font-size: 0.8rem;
    font-weight: 600;
    padding: 1rem;
    text-align: left;
  }

  .rotate {
    transform: rotate(180deg);
    transition: all 0.1s ease;
    font-size: 2rem;
    width: 50px;
  }

  .normal {
    transform: rotate(0deg);
    /* transition: all 0.1s ease; */
    font-size: 2rem;
    width: 50px;
  }

  .answerContent{
    color: #000;
  }

  .answerContainer {
    text-align: left;
    background-color: #fff;
    /* color: #A60000; */
  }

  .answerContent {
      padding: 1rem;
    }

    .displayAnswer {
      visibility: visible;
      opacity: 1;
      /* transition: all 500ms ease-in; */
      font-size: 0.8rem;

    }

    .hideAnswer {
      visibility: hidden;
      opacity: 0;
      max-height: 0;
      /* transition: all 500ms ease-out; */
      font-size: 0.8rem;
    }

@media only screen and (min-width: 765px) {
        .FaqQuestionContainer {
            font-size: 1rem;
        }
        
  
        .questionStyle {
          font-size: 1rem;
        }

        .displayAnswer {
            font-size: 1rem;
        }
    }

    
@media only screen and (min-width: 765px) {
    .hideAnswer{
        font-size: 1rem;
    }
}
    
      