.courseContainer{
    margin: 5%;
}

.courseHeader{
    margin-top: 50px;
}

.courseRow{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin: 30px 0;
}

.courseRow div, .courseRow ul{
    flex-basis: 100%;
}

.courseContainer .courseTitle{
    background-color:#A60000 ;
    position: relative;
    padding: 0 10px;
    text-transform: uppercase;
    color: #fff;
}

.courseContainer .courseTitle::before{
    content: "";
    position: absolute;
    top: -10px;
    left: 10px;
    border: 2px solid #000;
    width: 100px;
    padding: 25px;
    z-index: -1;
}

.listRow{
    margin: 30px;
}

.courseDuration{
    /* border: 2px solid #000; */
    background-color: #ffbb008e;
    padding: 10px 0;
}

.courseDuration .courseHours{
    display: flex;
    color: #A60000;
    border-bottom: 1px solid #fff;
    padding: 10px;
}

.courseDuration .courseHours span{
    font-weight: 500;
}

.courseDuration .courseHours h3{
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 600;
}

.courseDuration .courseOpportunities{
    padding: 10px;
}

.courseDuration .courseOpportunities p{
    font-weight: 500;
}

.courseDuration .courseOpportunities span{
    color: #A60000;
}