.contactDetailsContainer{
    border-radius: 40px 0 40px 0;
    background: linear-gradient(15deg, rgba(255, 186, 0, 0.18) 1.7%, rgba(255, 186, 0, 0.00) 102.34%);
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
    margin: 2% 5%;
    padding: 5%;
}

.contactDetailsContainer .contactMessage{
    border-bottom: 2px solid #FFBA00;
    margin-bottom: 30px;
    padding-bottom: 30px;
}

.contactDetailsContainer .contactMessage h3{
    font-size: 2rem;
    color: #A60000;
    font-weight: 600;
}

.contactDetailsContainer .contactMessage p{
    margin-top: 15px;
}

.contactDetailsContainer .titleText{
    font-weight: 600;
}

/* @media screen and (max-width: 768px) {
    .contactDetailsContainer{
        flex-direction: column;
    }
} */