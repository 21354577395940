/* .cursor {
    display: inline-block;
    background-color: black;
    margin-left: 5px;
    animation: blink 1s steps(2) infinite;
  }
  
  @keyframes blink {
    to {
      visibility: hidden;
    }
  }
  
  .hidden {
    visibility: hidden;
  } */

.typewriter .cursor {
    display: inline-block;
    width: 2px;
    background-color: currentColor;
    margin-left: 3px;
    animation: blink 1s infinite;
}

@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}