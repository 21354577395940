.partnersContainer{
    margin: 2% 5%;
    padding: 5% 0;
}

.partnersRow{
    display: flex;
    align-items: center;
}

.partnersTitle{
    font-size: 2rem;
    font-weight: 600;
}

.partnersMainText{
    width: 55%;
}

.partnersTable {
    width: 100%;
    border-collapse: collapse; 
}

.partnersTable, td {
    border: none; 
}

td {
    border-bottom: 1px solid #CEC9C9;
    border-right: 1px solid #CEC9C9; 
    padding: 20px; 
    text-align: center; 
}

tr:last-child td {
    border-bottom: none;
}

td:last-child {
    border-right: none; 
}

.partnersTable .pics{
    width: 150px;
}

@media screen and (max-width: 768px) {
    .partnersRow{
        flex-direction: column;
        text-align: center;
    }

    .partnersTitle{
        font-size: 2rem;
    }
    
    .partnersMainText{
        width: 100%;
        margin-bottom: 30px;
    }

    .partnersTable .pics{
        width: 80px;
    }
}