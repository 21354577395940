.trainingContainer{
    margin: 2% 5%;
    padding: 5% 0;
    border-radius: 40px 0 40px 0;
    background-color: #F2C94C;
}

.trainingHeader{
    text-align: center;
}

.trainingHeader .trainingTitle{
    font-weight: 600;
}

.trainingRow{
    display: flex;
    justify-content: center;
    gap: 60px;
    padding: 5%;
}

.arrowIcon{
    width: 20px;
}

.trainingRow .trainingList{
    list-style: none;
}

.trainingList .item{
    display: flex;
    align-items: center;
    gap: 5px;
}

@media screen and (max-width: 400px) {
    .trainingRow{
       flex-direction: column;
       gap: 0;
    }
}