.certificationsInnerContainer{
    background: linear-gradient(115deg, #FFF -9.3%, rgba(255, 186, 0, 0.18) 96.95%);
    display: flex;
    padding: 5%;
    text-align: center;
}

.certificationsInnerContainer div{
    padding: 50px;
}

.certificationsContainer div h3{
    font-size: 2rem;
    font-weight: 600;
    color: #A60000;
}

.certificationsContainer div p{
    font-size: 1.5rem;
}

.certificationsInnerContainer div:first-child{
    border-right:3px solid #A60000;
}

.courseAdse{
    margin: 3% 5%;
    padding: 5% 0;
    text-align: center;
    background-color: #FFBA00;
    border-radius: 40px 0 40px 0;
}

.certificateIcon{
    font-size: 80px;
}

@media screen and (max-width: 768px) {
    .certificationsInnerContainer{
        flex-direction: column;
    }

    .certificationsInnerContainer div:first-child{
        border-right: none;
        border-bottom:3px solid #A60000;
    }

    .certificationsContainer div h3{
        font-size: 1.5rem;
    }
    
    .certificationsContainer div p{
        font-size: 1rem;
    }

    .certificateIcon{
        font-size: 60px;
    }
}