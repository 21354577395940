.heroContainer{
    margin: 5%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.heroContainer h1{
    font-weight: 600;
}

.heroContainer img{
    width: 100%;
    border-radius: 30px;
    height: 400px;
}