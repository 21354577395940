.courseOptionContainer{
    background: linear-gradient(115deg, #FFF -9.3%, rgba(255, 186, 0, 0.18) 96.95%);
    /* display: flex; */
    padding: 5%;
    text-align: center;
}

.courseOptionInnerContainer{
    display: flex;
    justify-content: space-between;
}

.courseOptionInnerContainer div{
    padding: 50px;
    flex-basis: 100%;
}

.courseOptionContainer div h3{
    font-size: 2rem;
    font-weight: 600;
    color: #A60000;
    margin-bottom: 20px;
}

.courseOptionContainer ul{
    text-align: left;
}

.courseOptionContainer div p{
    font-size: 1.5rem;
}

.courseOptionInnerContainer div:first-child{
    border-right:3px solid #A60000;
}

.courseAdse{
    margin: 3% 5%;
    padding: 5% 0;
    text-align: center;
    background-color: #FFBA00;
    border-radius: 40px 0 40px 0;
}

@media screen and (max-width: 768px) {
    .courseOptionInnerContainer{
        flex-direction: column;
    }

    .courseOptionInnerContainer div:first-child{
        border-right: none;
        border-bottom:3px solid #A60000;
    }

    .courseOptionContainer div h3{
        font-size: 1.5rem;
    }
    
    .courseOptionContainer div p{
        font-size: 1rem;
    }

}