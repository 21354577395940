.aboutHeader{
    background-color: #FFBA00;
    border-radius: 40px 0 40px 0;
    margin: 2% 5%;
    padding: 5% 0;
}

.headerImageContainer{
    margin: 2% 5%;
    height: 400px;
}

.headerImage{
    border-radius: 40px;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.aboutHeader{
    display: flex;
    gap: 50px;
    padding: 2.5rem;
}

.aboutTitle{
    font-weight: 600;
    font-size: 2.5rem;
}

.aboutColumn{
    padding: 0 0 0 100px;
    text-align: left;
}

.aboutMainText{
    margin-bottom: 20px;
}


@media screen and (max-width: 768px) {
    .aboutHeader{
        flex-direction: column;
    }

    .aboutColumn{
        padding: 0;
    }
}